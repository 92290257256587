import styles from "./index.module.css";

export default function Banner() {
	return (
		<>
			<div className={styles["container"]}>
				<div className={styles["max-wrap"]}>
					<div className={styles["title"]}>
						Transform Your Productivity with Our Advanced Solutions
						{/* <div className={styles["subtitle"]}>a New Language</div> */}
					</div>
					<div className={styles["wrap"]}>
						<div className={styles["left"]}>
							<div className={styles["content"]}>
								Boost your productivity and overcome obstacles with our
								innovative tools. Streamline your workflow and achieve your
								highest potential.
							</div>
						</div>
						<div className={styles["right"]}>
							<img
								src="/imgs/question-mark.svg"
								className={styles["question-mark"]}
								alt=""
							/>
							<img src="/imgs/man.svg" className={styles["man"]} alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
