import styles from "./index.module.css";

export default function JapanApp() {
	return (
		<>
			<div className={styles["container"]}>
				<div className={styles["max-wrap"]}>
					<div className={styles["title"]}>
						Discover Our Innovative Applications
					</div>
					<div className={styles["content"]}>
						Boost productivity with our effective, time-saving, and
						cost-efficient solutions designed to enhance your workflow,
						security, and creativity.
					</div>
					<div className={styles["wrap"]}>
						<div className={styles["left"]}>
							<div className={styles["wrap-left"]}>
								<img src="/imgs/bg-jp.svg" className={styles["bg"]} alt="" />
								<div className={styles["wrap-phone"]}>
									{/* <img
										src="/imgs/white.svg"
										className={styles["white"]}
										alt=""
									/> */}
									<img
										src="/imgs/japanese.png"
										className={styles["phone"]}
										alt=""
									/>
								</div>
							</div>
						</div>
						<div className={styles["right"]}>
							<div className={styles["title-right"]}>
								Authenticator App
								<img src="/imgs/line.svg" className={styles["line"]} alt="" />
							</div>
							<div className={styles["content-right"]}>
								Our Authenticator app provides robust security features,
								including two-factor authentication and password management.
								Safeguard your accounts, track authentication progress, and stay
								protected against threats. Feel confident and secure in all your
								digital interactions.
							</div>
							<div className={styles["wrap-button"]}>
								<a
									className={styles["button1"]}
									href="https://apps.apple.com/us/app/authenticator-app-2fa/id6446979632"
									target="_blank"
									rel="noreferrer">
									<img src="/imgs/black-apple.svg" alt="" />
									<div>App Store</div>
								</a>
								<a
									className={styles["button2"]}
									href="https://play.google.com/store/apps/details?id=com.authenticator.app.starnest"
									target="_blank"
									rel="noreferrer">
									<img src="/imgs/white-android.svg" alt="" />
									<div>Play Store</div>
								</a>
							</div>
						</div>
					</div>
					<img src="/imgs/brush.svg" className={styles["brush"]} alt="" />
				</div>
			</div>
		</>
	);
}
