import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import weeklyPlanner from '../../assets/svg/weekly-img.svg';

const WeeklyPlanner = () => {
    return (
        <section className={clsx('wrap-res', styles['weekly__planner'])}>
            <div className={styles['weekly__planner--text']}>
                <h3 className={clsx('digital__title')}>Maximize your productivity with a Digital Weekly Planner</h3>
                <p className={clsx('digital__description')}>
                    Creating a bullet journal weekly planner is a great way to organize your life as schedule
                    important events, birthdays or anniversaries....At the end of the week, review your
                    progress and reflect on what went well and what could have gone better
                </p>
            </div>
            <div className={styles['weekly__planner--img']}>
                <img src={weeklyPlanner} alt="" />
            </div>
        </section>
    );
};

export default WeeklyPlanner;
