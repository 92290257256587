import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import logoImg from "../../../assets/svg/logo.svg";
import starIcon from "../../../assets/svg/star.svg";
import arrowDownIcon from "../../../assets/svg/arrow-down-outline.svg";
import TemplateStore from "./TemplateStore";
import $ from "jquery";

import "../../../assets/styles/_base.scss";
import { Link } from "react-router-dom";

function JournalStore() {
	const [showFullText, setShowFullText] = useState(false);
	const [showFullLanguage, setShowFullLanguage] = useState(false);

	return (
		<>
			<div className={styles["root"]}>
				<div className={styles["root__appbar"]}>
					{/* <div className={styles["root__appbar--img"]}>
						<Link to="/journal">
							<img src={backIcon} alt="back" />
						</Link>
					</div>
					<div className={styles["root__appbar--img"]}>
						<img src={flagIcon} alt="flag" />
					</div> */}
				</div>
				<div className={styles["root__header"]}>
					<div className={styles["root__header__img"]}>
						<img src={logoImg} alt="" />
					</div>
					<h3 className={styles["root__header__title"]}>
						Journal & Digital Planner 2023
					</h3>
				</div>
				<div className={styles["root__cate"]}>
					<div className={styles["root__cate--item"]}>
						<h4>161.4 MB</h4>
						<p>Size</p>
					</div>
					<div className={styles["root__cate--item"]}>
						<h4>Category</h4>
						<p>Productivity</p>
					</div>
					<div className={styles["root__cate--item"]}>
						<h4>4.7</h4>
						<div className={styles["root__cate--item--star"]}>
							<img src={starIcon} alt="" />
							<img src={starIcon} alt="" />
							<img src={starIcon} alt="" />
							<img src={starIcon} alt="" />
							<img src={starIcon} alt="" />
						</div>
					</div>
					<div className={styles["root__cate--item"]}>
						<h4>Free</h4>
						<p>Price</p>
					</div>
				</div>
				<div
					className={clsx(
						styles["root__btn"],
						"printerest_click_to_apple_store"
					)}
					id="printerest_click_to_apple_store2"
					data-pin-custom="true"
					data-pin-do="buttonPin"
					data-pin-media="the-url-of-the-image-here"
					data-pin-count="beside">
					<Link
						to={
							"https://apps.apple.com/vn/app/s%E1%BB%95-tay-ghi-ch%C3%BA-k%E1%BA%BF-ho%E1%BA%A1ch/id1664348099?l=vi"
						}>
						<button className="btn-direct-page">Access the App Store</button>
					</Link>
				</div>
				<TemplateStore />
				<div className={styles["root__description"]}>
					<h3 className={styles["root__description--title"]}>Description</h3>
					<div className={styles["root__description--suptitle"]}>
						<div
							className={
								styles[
									!showFullText
										? "root__description--suptitle--base"
										: "root__description--suptitle--more"
								]
							}>
							<p>
								Don't miss important tasks to perform, manage your time and
								organize your work effectively. Set and be motivated to{" "}
								{!showFullText && (
									<button
										onClick={() => setShowFullText(!showFullText)}
										className={styles["root__btn--more"]}>
										More
									</button>
								)}
								accomplish important goals, motivate yourself.
							</p>
							<p>Relax and entertain after studying and working hard.</p>
							<p>
								Increase creativity and artistic thinking, inspire life and
								dispel pressures.
							</p>
							<p>
								Study Handbook. Write, and draw to take notes on lectures in
								class.
							</p>
							<p>
								Create folders to store PDF documents, videos, and links, and
								create timetables, and reminders to compose articles, and
								presentations.
							</p>
							<p>
								Add hyperlinks to external websites, videos, and articles to
								build a map of your knowledge.
							</p>
							<p>
								Back up all your notes to iCloud, syncing across all your
								devices so you never lose them.
							</p>
							<p>
								Become a fully professional note-taker without papers - Secure
								and lock your notes with Touch ID.
							</p>
							<p>
								You can use it on your iPad, iPhone with Apple Pencil, or
								digital planner to remind you online of important events. (Will
								support Mac in the future)
							</p>
							<p>
								We work hard to make it a genuine (BULLET) JOURNAL& DIGITAL
								PLANNER.
							</p>
							<p>
								With (BULLET) JOURNAL & DIGITAL PLANNER APP you will be free to
								create with more than +500 templates, and +3000 stickers to help
								you freely create unlimited.
							</p>
							<p>
								It's full of tools to help you quickly complete your (BULLET)
								JOURNAL & DIGITAL PLANNER APP.
							</p>
							<p>
								In addition, we also combine digital planner features such as a
								calendar, and tasks aimed to help you digitally plan important
								events or tasks to be reminded.
							</p>
							<p>Manage time and arrange work efficiently</p>
							<p>
								Bullet Journal is considered a very effective method for
								individuals to take notes on tasks that need to be done and
								ensure that they are completed on time. "Goldfish brains" can
								become confident to manage their work better, limiting the
								situation of "missing out" everything because they have Bullet
								Journal with them.
							</p>
							<p>Motivate yourself</p>
							<p>
								(BULLET) JOURNAL & DIGITAL PLANNER APP helps individuals have
								more strength and motivation to accomplish the goals set. Turn
								each page in the Bullet Journal, each goal, and the task will
								immediately catch your eye, prompting you to do it, and beat the
								laziness that's in you. Moreover, Bullet Journal also brings
								many inspirations, helping you dispel fatigue and pressure from
								work and study. Because when you take the time to write and
								decorate the notebook, you will focus all your efforts and
								forget about your troubles and sorrows.
							</p>
							<p> Inspire life, dispel pressures</p>
							<p>
								(BULLET) JOURNAL & DIGITAL PLANNER APP brings many inspirations,
								helping you dispel fatigue and pressure from work and study.
								Because when you take the time to write and decorate the
								notebook, you will focus all your efforts and forget about the
								troubles, and sadness, feel relaxed and get to know yourself
								better.Besides, Bullet Journal also helps you develop your
								creativity and think better.
							</p>
							<p>
								Some examples that you can easily get started with the (BULLET)
								JOURNAL & DIGITAL PLANNER APP:
							</p>
							<p>
								- Planner Journal: You can choose a bullet journal according to
								your personal preferences and style. It can be pre-decorated or
								blank notebooks so you can unleash your creativity.
							</p>
							<p>
								- Future Log: Besides taking notes on tasks that need to be
								done, the Bullet Journal also helps you save your goals, and
								plan to do so over a longer time such as weeks, months, or even
								years. Planning important tasks and coding in your sign language
								will give you more motivation to try.
							</p>
							<p>
								- Monthly Log, Weekly Log, Daily Log: save daily, weekly, and
								monthly work and study schedules. Hand-drawn calendar pages and
								lovely sketches will help you remember work easier than
								traditional calendars.
							</p>
							<p>Revealing some effective Bullet Journal creative methods:</p>
							<p>1. Set the basics</p>
							<p>- Choosing the right type of journal </p>
							<p>- Set up a table of contents</p>
							<p>- Fill in plans </p>
							<p>- Record the plan monthly, day by day </p>
							<p>- Updating the table of contents </p>
							<p>2. Make a personal mark </p>
							<p>- Use the Rapid Logging technique</p>
							<p>- Store personal collections </p>
							<p>- Record your meaningful events. </p>
							<p>3. Take notes and checkbooks regularly</p>
						</div>
					</div>
				</div>
				<div className={styles["root__info"]}>
					<h3 className={styles["root__info--title"]}>Information</h3>
					<div className={styles["root__info--desc"]}>
						<p>Developer</p>
						<span>Tuat Tran</span>
					</div>
					<div className={styles["root__info--desc"]}>
						<p>Category</p>
						<span>Productivity</span>
					</div>
					<div className={styles["root__info--desc"]}>
						<p>Age</p>
						<span>4+</span>
					</div>
					<div
						className={
							styles[
								!showFullLanguage ? "root__info--desc" : "root__info--language"
							]
						}>
						<p>Languages </p>
						{!showFullLanguage && (
							<button
								className={styles["root__btn--more"]}
								onClick={() => setShowFullLanguage(!showFullLanguage)}>
								<span>26 Languages</span>
								<img src={arrowDownIcon} alt="arrow down" />
							</button>
						)}
						{showFullLanguage && (
							<span>
								English, Croatian, Czech, Danish, Dutch, Finnish, French,
								German, Greek, Hungarian, Indonesian, Italian, Japanese, Korean,
								Norwegian Bokmål, Polish, Portuguese, Romanian, Russian,
								Spanish, Swedish, Thai, Traditional Chinese, Turkish, Ukrainian,
								Vietnamese
							</span>
						)}
					</div>
					<div className={styles["root__info--desc"]}>
						<p>Size</p>
						<span>161.4 MB</span>
					</div>
					<div className={styles["root__info--desc"]}>
						<p>Price</p>
						<span>Free</span>
					</div>
				</div>
			</div>
			<div className={styles["root__footer"]}>
				<div className={styles["root__footer--logo"]}>
					<div className={styles["root__footer--logo--img"]}>
						<img src={logoImg} alt="" />
					</div>
					<h3 className={styles["root__footer--logo--title"]}>
						Journal & Digital Planner 2023
					</h3>
				</div>
				<div
					className={clsx(
						styles["root__btn"],
						"printerest_click_to_apple_store1"
					)}
					id="printerest_click_to_apple_store1"
					data-pin-custom="true"
					data-pin-do="buttonPin"
					data-pin-media="the-url-of-the-image-here"
					data-pin-count="beside">
					<Link
						to={
							"https://apps.apple.com/vn/app/s%E1%BB%95-tay-ghi-ch%C3%BA-k%E1%BA%BF-ho%E1%BA%A1ch/id1664348099?l=vi"
						}>
						<button className="btn-direct-page">Access the App Store</button>
					</Link>
				</div>
			</div>
		</>
	);
}

export default JournalStore;
