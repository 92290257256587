import Banner from "./banner";
import JapanApp from "./japan-app";
import OtherApps from "./other-app";

export default function Home() {
	return (
		<>
			<Banner />
			<JapanApp />
			<OtherApps />
		</>
	);
}
