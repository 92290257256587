import React from "react";
import Header from "../../components/Header";
import Feature from "../../components/Journal/Feature";
import Footer from "../../components/Footer";
import TemplateSection from "../../components/TemplateSection";
import FeatureSection from "../../components/FeatureSection";
import TutorialSection from "../../components/TutorialSection";
import clsx from "clsx";

const FeaturePage = () => {
  return (
    <>
      <Header />
      <Feature />
      <div className={clsx("feature__template")}>
        <TemplateSection />
      </div>
      <FeatureSection />
      <TutorialSection />
      <Footer />
    </>
  );
};

export default FeaturePage;
