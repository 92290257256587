import React from 'react';
import Header from '../../components/Header';
import Pricing from '../../components/Journal/Pricing';
import Footer from '../../components/Footer';

const PricingPage = () => {
    return (
        <>
            <Header />
            <Pricing />
            <Footer />
        </>
    );
};

export default PricingPage;
