import React from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import styled from "@emotion/styled";
import "swiper/swiper.scss";

import styles from "./styles.module.scss";
import featureIcon1 from "../../assets/svg/feature1.svg";
import featureIcon2 from "../../assets/svg/feature2.svg";
import featureIcon3 from "../../assets/svg/feature3.svg";
import featureIcon4 from "../../assets/svg/feature4.svg";
import featureIcon5 from "../../assets/svg/feature5.svg";
import featureIcon6 from "../../assets/svg/feature6.svg";
import featureIcon7 from "../../assets/svg/feature7.svg";
import featureIcon8 from "../../assets/svg/feature8.svg";
import FeatureItem from "../FeatureItem";

const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    width: 379px !important;
    height: 390px;
  }

  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper.css-1wr7zb8 {
    border-radius: 20px;
  }

  .swiper-wrapper {
    border-radius: 20px;
    padding: 30px 0 80px 0;
  }

  @media only screen and (min-width: 320px) and (max-width: 598px) {
    .swiper-slide {
      width: 204px !important;
      height: 234px !important;
      margin: 20px 0;
    }

    .swiper-wrapper {
      padding: 0 !important;
    }
  }
`;

const FeatureData = [
  {
    id: 1,
    icon: featureIcon1,
    title: "Lasso Tool",
    desc: "Select objects on the page, easy to move, duplicate, copy, resize, rotate them",
  },
  {
    id: 2,
    icon: featureIcon2,
    title: "Drag & drop images from internet",
    desc: "Drag and drop images from the internet to your journal quickly and conveniently",
  },
  {
    id: 3,
    icon: featureIcon3,
    title: "Hyperlink",
    desc: "Link to pages, link to events, Link to URL",
  },
  {
    id: 4,
    icon: featureIcon4,
    title: "PDF anotation",
    desc: " Import PDF, take note, anotate anything you want",
  },
  {
    id: 5,
    icon: featureIcon5,
    title: "Go to page",
    desc: "Easily view all pages and select any page you want with a single tap",
  },
  {
    id: 6,
    icon: featureIcon6,
    title: "Reoder",
    desc: "Drag and drop pages anywhere you want with just one touch to arrange them in an arbitrary order",
  },
  {
    id: 7,
    icon: featureIcon7,
    title: "Image Tool",
    desc: "Import, move, resize, rotate, or crop images on your page to create multimedia notes. Maintain aspect ratio or adjust freely while resizing",
  },
  {
    id: 8,
    icon: featureIcon8,
    title: "Text box tool",
    desc: "Type with your keyboard and format text, highlight, adjust color, fontsize",
  },
];

const FeatureSection = () => {
  return (
    <section className={styles["feature"]}>
      <h1 className={clsx("feature__title")}>More features at a glance</h1>
      <div className={styles["feature__list"]}>
        <StyledSwiper
          slidesPerView={1.85}
          spaceBetween={16}
          autoplay={{
            delay: 2500,
          }}
          loopedSlides={2}
          initialSlide={4}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 1.85,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4.3,
              spaceBetween: 61,
            },
          }}
          centeredSlides={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {FeatureData.map((features) => (
            <SwiperSlide key={features.id}>
              <FeatureItem
                icon={features.icon}
                title={features.title}
                desc={features.desc}
              />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </div>
    </section>
  );
};

export default FeatureSection;
