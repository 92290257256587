import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "swiper/swiper.scss";

import styles from "./styles.module.scss";
import hightligh1 from "../../assets/images/hightlight1.png";
import hightligh2 from "../../assets/images/hightlight2.png";
import hightligh3 from "../../assets/images/hightlight3.png";
import hightligh4 from "../../assets/images/hightlight4.png";
import hightligh5 from "../../assets/images/hightlight5.png";
import styled from "@emotion/styled";

const hightlightData = [
  {
    id: 1,
    img: hightligh1,
  },
  {
    id: 2,
    img: hightligh2,
  },
  {
    id: 3,
    img: hightligh3,
  },
  {
    id: 4,
    img: hightligh4,
  },
  {
    id: 5,
    img: hightligh5,
  },
];

const StyledSwiper = styled(Swiper)`
    .swiper-slide {
        width: 448px !important;
    }

    @media only screen and (min-width: 320px) and (max-width: 598px) {
        .swiper-slide {
            width: 199px !important;
        }
    }
`;

const HighlightSection = () => {
    return (
        <section className={styles["highlight__section"]}>
            <div className={styles["highlight__section--img"]}>
                <StyledSwiper
                    mousewheel={true}
                    slidesPerView={1.8}
                    spaceBetween={16}
                    autoplay={{
                        delay: 2500,
                    }}
                    loop={true}
                    loopedSlides={2}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 16,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                    }}
                    centeredSlides={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                >
                {hightlightData.map((highlight) => (
                    <SwiperSlide key={highlight.id}>
                        <div className={styles["highlight__item"]}>
                            <img src={highlight.img} alt="" />
                        </div>
                    </SwiperSlide>
                ))}
                </StyledSwiper>
            </div>
        </section>
    );
};

export default HighlightSection;
