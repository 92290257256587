import React from 'react'
import Index from '../components/mail'

function Mail() {
    return (
        <>
            <Index />
        </>
    )
}

export default Mail