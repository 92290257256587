import React from "react";

import styles from "./styles.module.scss";
import tempStore1 from "../../../../assets/images/tempStore1.png";
import tempStore2 from "../../../../assets/images/tempStore2.png";
import tempStore3 from "../../../../assets/images/tempStore3.png";
import tempStore4 from "../../../../assets/images/tempStore4.png";
import tempStore5 from "../../../../assets/images/tempStore5.png";
import tempStore6 from "../../../../assets/images/tempStore6.png";
import tempStore7 from "../../../../assets/images/tempStore7.png";
import tempStore8 from "../../../../assets/images/tempStore8.png";
import tempStore9 from "../../../../assets/images/tempStore9.png";
import tempStore10 from "../../../../assets/images/tempStore10.png";
// import tempStore11 from "../../../../assets/images/tempStore11.svg";
// import tempStore12 from "../../../../assets/images/tempStore12.svg";
// import tempStore13 from "../../../../assets/images/tempStore13.svg";
// import tempStore14 from "../../../../assets/images/tempStore14.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "swiper/swiper.scss";
import styled from "@emotion/styled";
import clsx from "clsx";

const dataTemplate = [
	{
		id: 1,
		img: tempStore1,
	},
	{
		id: 2,
		img: tempStore2,
	},
	{
		id: 3,
		img: tempStore3,
	},
	{
		id: 4,
		img: tempStore4,
	},
	{
		id: 5,
		img: tempStore5,
	},
	{
		id: 6,
		img: tempStore6,
	},
	{
		id: 7,
		img: tempStore7,
	},
	{
		id: 8,
		img: tempStore8,
	},
	{
		id: 9,
		img: tempStore9,
	},
	{
		id: 10,
		img: tempStore10,
	},
	// {
	// 	id: 11,
	// 	img: tempStore11,
	// },
	// {
	// 	id: 12,
	// 	img: tempStore12,
	// },
	// {
	// 	id: 13,
	// 	img: tempStore13,
	// },
	// {
	// 	id: 14,
	// 	img: tempStore14,
	// },
];

const StyledSwiper = styled(Swiper)`
	@media only screen and (min-width: 320px) and (max-width: 598px) {
		.swiper-slide {
			/* width: 143px !important; */
			width: 105px !important;
			margin-right: 8px !important;
		}
	}
`;

const TemplateStore = () => {
	return (
		<section className={clsx("template", styles["template"])}>
			<div className={styles["template__list"]}>
				<StyledSwiper
					speed={500}
					// slidesPerView={4}
					slidesPerView={"auto"}
					// spaceBetween={27}
					autoplay={false}
					loop={false}
					// loopedSlides={4}
					// initialSlide={8}
					modules={[Autoplay]}
					className={styles["mySwiper"]}>
					{dataTemplate.map((template) => (
						<SwiperSlide key={template.id}>
							<div className={styles["template__item"]}>
								<img src={template.img} alt="" />
							</div>
						</SwiperSlide>
					))}
				</StyledSwiper>
			</div>
		</section>
	);
};

export default TemplateStore;
