import React from "react";
import styles from "./style.module.scss";
import peopleBanner from "../../../assets/images/Pricing/peopleBannerPricing.png";
import decorativeBanner from "../../../assets/images/Pricing/decorativeBannerPricing.png";
import blobMidPricing from "../../../assets/images/Pricing/blobMidBannerPricing.png";
import blobBotPricing from "../../../assets/images/Pricing/blobBotBannerPricing.png";
import firstIcon from "../../../assets/images/Pricing/firstIconRight.svg";
import secondIcon from "../../../assets/images/Pricing/secondIconRight.svg";
import thirdIcon from "../../../assets/images/Pricing/thirdIconRight.svg";
import fourthIcon from "../../../assets/images/Pricing/fourthIconRight.svg";
import fifthIcon from "../../../assets/images/Pricing/fifthIconRight.svg";
import sixthIcon from "../../../assets/images/Pricing/sixthIconRight.svg";
import sevenIcon from "../../../assets/images/Pricing/sevenIconRight.svg";
import IconQuestionMark from "../../../assets/images/Pricing/iconQuestionMark.svg";

import "../../../assets/styles/_base.scss";
import clsx from "clsx";
import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <>
      <div className={styles["root"]}>
        <div className={clsx(styles["root__banner"], "wrap-res")}>
          <div className={styles["root__banner__content"]}>
            <div className={styles["root__banner__content--imgPeople"]}>
              <img src={peopleBanner} alt="" />
            </div>
            <div className={styles["root__banner__content--des"]}>
              <div className={styles["root__banner__content--des--title"]}>
                Start your free trial with the Journal and Planner app
              </div>
              <div className={styles["root__banner__content--des--info"]}>
                Take note, import files, make plans... and many other attractive
                features. Let's download the app and explore many interesting
                things of the app for free, you will surely enjoy it.
              </div>
              <div className={styles["root__banner__content--des--button"]}>
                <Link to={"https://apps.apple.com/us/app/id1664348099"}>
                  <button className={clsx("btn--download")}>
                    Free download
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles["root__banner__content--imgDecorative"]}>
            <img src={decorativeBanner} alt="" />
          </div>
          <div className={styles["root__banner__content--imgBlobMid"]}>
            <img src={blobMidPricing} alt="" />
          </div>
          <div className={styles["root__banner__content--imgBlobBot"]}>
            <img src={blobBotPricing} alt="" />
          </div>
        </div>
        <div className={clsx(styles["root__invite"], "wrap-res")}>
          <div className={styles["root__invite--left"]}>
            <div className={styles["root__invite--left--leftContent"]}>
              <h1>Attractive offers</h1>
              <div>
                <span>Invite more than 3 friends you will get</span>
                <span>30%</span>
                <span>discount for lifetime premium package</span>
              </div>
              <div>
                <span>Invite more than 5 friends you will get</span>
                <span>30%</span>
                <span>discount for lifetime premium package</span>
              </div>
              <div>
                <span>Invite more than 15 friends you will git</span>
                <span>30%</span>
                <span>lifetime premium package</span>
              </div>
            </div>
            <div className={styles["root__invite--button"]}>
              <Link to={"https://apps.apple.com/us/app/id1664348099"}>
                <button className={clsx("btn--download")}>Free download</button>
              </Link>
            </div>
          </div>
          <div className={styles["root__invite--right"]}>
            <h1>Upgrade to the full version</h1>

            <div className={styles["root__invite--right--rightContent"]}>
              <div>
                <img src={firstIcon} alt="" />
                <span>Unlimited pages and journals</span>
              </div>
              <div>
                <img src={secondIcon} alt="" />
                <span>Realistic drawing tools</span>
              </div>
              <div>
                <img src={thirdIcon} alt="" />
                <span>1000+ sticker and templates</span>
              </div>
              <div>
                <img src={fourthIcon} alt="" />
                <span>Professionally designed templates</span>
              </div>
              <div>
                <img src={fifthIcon} alt="" />
                <span>Import your photos</span>
              </div>
              <div>
                <img src={sixthIcon} alt="" />
                <span>Export and share with family & friends</span>
              </div>
              <div>
                <img src={sevenIcon} alt="" />
                <span>Unlock all features</span>
                <img
                  className={styles["root__invite--right--rightContent--icon"]}
                  src={IconQuestionMark}
                  alt=""
                />
              </div>
            </div>
            <div className={styles["root__invite--button"]}>
              <Link to={"https://apps.apple.com/us/app/id1664348099"}>
                <button className={clsx("btn--download")}>Free download</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
