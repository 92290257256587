import React from 'react';
import styles from './style.module.scss';

const FeatureItem = (props) => {
    const { icon, title, desc } = props;

    return (
        <div className={styles['feature__item']}>
            <img src={icon} alt="" />
            <h5>{title}</h5>
            <p>{desc}</p>
        </div>
    );
};

export default FeatureItem;
