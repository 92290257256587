import styles from "./index.module.css";

export default function OtherApps() {
	return (
		<>
			<div className={styles["container"]}>
				<div className={styles["max-wrap"]}>
					{/* Korean */}
					<div className={styles["wrap"]}>
						<div className={styles["right"]}>
							<div className={styles["title-right"]}>
								Journal: Note, <br></br>Planner, PDFs
								<img src="/imgs/line.svg" className={styles["line"]} alt="" />
							</div>
							<div className={styles["content-right"]}>
								The App empowers you to capture thoughts, track progress, and
								achieve your aspirations. Seamlessly integrated features allow
								for efficient organization and reflection. Elevate your
								productivity and creativity with a digital journaling experience
								that inspires.
							</div>
							<div className={styles["wrap-button"]}>
								<a
									className={styles["button1"]}
									href="https://apps.apple.com/us/app/journal-planner-notes-pdfs/id1664348099"
									target="_blank"
									rel="noreferrer">
									<img src="/imgs/white-apple.svg" alt="" />
									<div>App Store</div>
								</a>
								<a
									className={styles["button2"]}
									href="https://play.google.com/store/apps/details?id=journal.notes.planner.starnest"
									target="_blank"
									rel="noreferrer">
									<img src="/imgs/black-android.svg" alt="" />
									<div>Play Store</div>
								</a>
							</div>
						</div>
						<div className={styles["left"]}>
							<div className={styles["wrap-left"]}>
								<img
									src="/imgs/bg-korean.svg"
									className={styles["bg"]}
									alt=""
								/>
								<div className={styles["wrap-phone"]}>
									<img
										src="/imgs/korean.png"
										className={styles["phone"]}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
					{/* German */}
					<div className={styles["wrap"]}>
						<div className={styles["left"] + " " + styles["right-pic"]}>
							<div className={styles["wrap-left"]}>
								<img
									src="/imgs/bg-german.svg"
									className={styles["bg"]}
									alt=""
								/>
								<div className={styles["wrap-phone"]}>
									<img
										src="/imgs/german.png"
										className={styles["phone"]}
										alt=""
									/>
								</div>
							</div>
						</div>
						<div className={styles["right"]}>
							<div className={styles["title-right"]}>
								Theme: Wallpapers <br></br>& Widgets
								<img src="/imgs/line.svg" className={styles["line"]} alt="" />
								<img src="/imgs/arrow.svg" className={styles["arrow"]} alt="" />
							</div>
							<div className={styles["content-right"]}>
								The App designed to elevate your device's aesthetics with a vast
								selection of customizable themes, stunning wallpapers, practical
								widgets, and diverse icon packs. Express your unique style and
								create a digital environment that reflects your personality
								while enhancing productivity and enjoyment.
							</div>
							<div className={styles["wrap-button"]}>
								{/* <a
									className={styles["button1"]}
									href="https://play.google.com/store/apps/details?id=com.themes.wallpapers.widgets.starnest"
									target="_blank"
									rel="noreferrer">
									<img src="/imgs/white-apple.svg" alt="" />
									<div>App Store</div>
								</a> */}
								<a
									className={styles["button2"]}
									href="https://play.google.com/store/apps/details?id=com.themes.wallpapers.widgets.starnest"
									target="_blank"
									rel="noreferrer">
									<img src="/imgs/black-android.svg" alt="" />
									<div>Play Store</div>
								</a>
							</div>
						</div>
					</div>
					{/* Toeic */}
					<div className={styles["wrap"]}>
						<div className={styles["right"]}>
							<div className={styles["title-right"]}>
								Ai Keyboard: <br></br>Grammar Check
								<img src="/imgs/line.svg" className={styles["line"]} alt="" />
							</div>
							<div className={styles["content-right"]}>
								Explore our AI Keyboard app, crafted to boost typing speed,
								generate custom content, and include advanced features like AI
								Reply and canned messages. Enjoy seamless communication and
								heightened productivity with a keyboard that adapts effortlessly
								to your unique style and preferences.
							</div>
							<div className={styles["wrap-button"]}>
								{/* <a
									className={styles["button1"]}
									href="https://apps.apple.com/us/app/prep-for-the-toeic-test/id1574067773"
									target="_blank"
									rel="noreferrer">
									<img src="/imgs/white-apple.svg" alt="" />
									<div>App Store</div>
								</a> */}
								<a
									className={styles["button2"]}
									href="https://play.google.com/store/apps/details?id=com.themes.wallpapers.widgets.starnest"
									target="_blank"
									rel="noreferrer">
									<img src="/imgs/black-android.svg" alt="" />
									<div>Play Store</div>
								</a>
							</div>
						</div>
						<div className={styles["left"]}>
							<div className={styles["wrap-left"]}>
								<img
									src="/imgs/bg-english.svg"
									className={styles["bg"]}
									alt=""
								/>
								<div className={styles["wrap-phone"]}>
									<img
										src="/imgs/pink.svg"
										className={styles["white"]}
										alt=""
									/>
									<img
										src="/imgs/english.png"
										className={styles["phone"]}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
					<img src="/imgs/brush-blue.svg" className={styles["brush"]} alt="" />
				</div>
			</div>
		</>
	);
}
