import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import habitTracker from '../../assets/images/habit-tracker.png';
import habitTrackerStar from '../../assets/svg/tracker-star.svg';
import squareLg from '../../assets/svg/square-lg.svg';
import blobTracker from '../../assets/svg/blob-tracker.svg';

function HabitTrackerSection() {
    return (
        <section className={clsx('wrap-res', styles['habit__tracker'])}>
            <div className={styles['habit__tracker--text']}>
                <h3 className={clsx('digital__title')}>Develop positive habits with a Digital Habit Tracker</h3>
                <p className={clsx('digital__description')}>
                    Do you want to build a effective habit tracking system, break bad ones and develop good
                    ones? Habit tracker help stay motivated and mindful, take accountability for going the
                    right way, stick to your plan, and you will soon feel how much better your life is
                    getting.
                </p>
            </div>
            <div className={styles['habit__tracker--img']}>
                <img src={habitTracker} alt="" />
            </div>
            <div className={styles['habit__tracker--star']}>
                <img src={habitTrackerStar} alt="" />
            </div>
            <div className={styles['habit__tracker--square--lg']}>
                <img src={squareLg} alt="" />
            </div>
            <div className={styles['habit__tracker--blob--tracker']}>
                <img src={blobTracker} alt="" />
            </div>
        </section>
    );
}

export default HabitTrackerSection;
