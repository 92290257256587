/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import styles from './styles.module.scss';
import logoImg from '../../assets/svg/logo.svg';
import arrowDownIcon from '../../assets/svg/arrow-down.svg';
import menuIcon from '../../assets/svg/menu-icon.svg';

const links = [
    { name: 'Home', path: '/journal', id: 'home' },
    { name: 'Library', path: '/journal/library', id: 'library' },
    { name: 'Feature', path: '/journal/feature', id: 'feature' },
    { name: 'Blog', path: '/journal/blog', id: 'blog' },
    { name: 'Pricing', path: '/journal/pricing', id: 'pricing' },
];

const options = [
    { value: 'english', label: 'English' },
    { value: 'indonesia', label: 'Indonesia' },
    { value: 'china', label: 'China' },
    { value: 'hindi', label: 'Hindi' },
    { value: 'korea', label: 'Korea' },
    { value: 'vietnam', label: 'Vietnam' },
];

const Header = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [showOptions, setShowOptions] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(options[0]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const languageSelectorRef = useRef(null);
    const overlayRef = useRef(null);
    const location = useLocation();

    const handleMenuClick = (e) => {
        const isMobileNav = e.target.closest(`.${styles['header__navigation--mobile']}`);
        const isMobileRight = e.target.closest(`.${styles['header__right--mobile']}`);

        if (!isMobileNav && !isMobileRight) {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (languageSelectorRef.current && !languageSelectorRef.current.contains(event.target)) {
                setShowOptions(false);
            }

            if (overlayRef.current && overlayRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [languageSelectorRef, overlayRef]);

    const handleOptionClick = (option) => {
        setSelectedLanguage(option);
        setShowOptions(false);
    };

    useEffect(() => {
        const path = location.pathname;
        const link = links.find((l) => l.path === path);
        if (link) {
            setActiveLink(link.id);
        }
    }, [location]);

    const handleLinkClick = (linkId) => {
        setActiveLink(linkId);
    };

    return (
        <div className={styles['header']}>
            <div className={clsx('wrap-res', styles[`header__res`])}>
                <Link to="/journal">
                    <div className={styles['header__logo']}>
                            <img src={logoImg} alt="" className={styles['header__logo--img']} />
                            <div className={clsx('header__logo--slogan')}>
                                <p>
                                    JOURNAL <span>AND</span> PLANNER
                                </p>
                            </div>
                    </div>
                </Link>

                <div className={styles['header__navigation']}>
                    <ul>
                        {links.map(({ name, path, id }) => (
                            <li key={id}>
                                <Link
                                    to={path}
                                    className={activeLink === id ? styles['active'] : ''}
                                    onClick={() => handleLinkClick(id)}
                                >
                                    {name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={styles['header__right']}>
                    <div className={styles['header__right--btn']}>
                        <Link to={'https://apps.apple.com/us/app/id1664348099'}>
                            <button className={clsx('btn--download')}>Free download</button>
                        </Link>
                    </div>
                    <div
                        className={styles['header__right--select']}
                        onClick={() => setShowOptions(!showOptions)}
                        ref={languageSelectorRef}
                    >
                        <div className={styles['select__lang']}>{selectedLanguage.label}</div>
                        <div className={showOptions ? styles['select__arrow--active'] : styles['select__arrow']}>
                            <img src={arrowDownIcon} alt="" />
                        </div>
                        {showOptions && (
                            <div className={styles['options']}>
                                {options.map((option) => (
                                    <div
                                        key={option.value}
                                        className={`${styles['option']} ${
                                            selectedLanguage.value === option.value ? styles['selected'] : ''
                                        }`}
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        {option.label}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {isMenuOpen && <div className={styles[`overlay`]} ref={overlayRef}></div>}
                <div className={styles['header__menu']} onClick={handleMenuClick}>
                    <img
                        src={menuIcon}
                        alt=""
                        className={isMenuOpen ? styles['header__menu--show--icon'] : styles['header__menu--hide--icon']}
                    />
                    {isMenuOpen && (
                        <>
                            <div className={styles['header__navigation--mobile']}>
                                <ul>
                                    {links.map(({ name, path, id }) => (
                                        <li key={id}>
                                            <Link
                                                to={path}
                                                className={activeLink === id ? styles['active'] : ''}
                                                onClick={() => handleLinkClick(id)}
                                            >
                                                {name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className={styles['header__right--mobile']}>
                                <div className={styles['header__right--btn']}>
                                    <a href="https://apps.apple.com/us/app/id1664348099">free download</a>
                                </div>
                                <div
                                    className={styles['header__right--select']}
                                    onClick={() => setShowOptions(!showOptions)}
                                    ref={languageSelectorRef}
                                >
                                    <div className={styles['select__lang']}>{selectedLanguage.label}</div>
                                    <div
                                        className={
                                            showOptions ? styles['select__arrow--active'] : styles['select__arrow']
                                        }
                                    >
                                        <img className={styles['select__arrow--lang']} src={arrowDownIcon} alt="" />
                                    </div>
                                    {showOptions && (
                                        <div className={styles['options']}>
                                            {options.map((option) => (
                                                <div
                                                    key={option.value}
                                                    className={`${styles['option']} ${
                                                        selectedLanguage.value === option.value
                                                            ? styles['selected']
                                                            : ''
                                                    }`}
                                                    onClick={() => handleOptionClick(option)}
                                                >
                                                    {option.label}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
