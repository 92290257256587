import axiosClient from "./axiosClient";

/**
 * @param payload
 ```
 {
    app_name: "Your app",
    email: "example@email.com,
    content: "mail content"
 }
 ```
 */
const sendEmail = async (payload) => {
  return axiosClient.post("/mail", payload);
};

export default sendEmail;
