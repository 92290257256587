import React, { useState } from "react";
import styles from "./style.module.scss";
import iconLine from "../../../assets/images/Blog/iconLine.svg";
import WhatNew from "./WhatNew";
import TipsForYou from "./TipsForYou";
import ShareYourStory from "./ShareYourStory";
import "../../../assets/styles/_base.scss";

const Blog = () => {
  const [activeButton, setActiveButton] = useState(1);
  return (
    <>
      <div className={styles["root"]}>
        <div className={styles["root__link"]}>
          <div className={styles["root__link--title"]}>
            <span style={{ color: "#FFCE00" }}>JOURNAL </span>
            <span>AND</span>
            <span style={{ color: "#FFCE00" }}>PLANNER</span>
          </div>
          <div className={styles["root__link--line"]}>
            <img src={iconLine} alt="" />
          </div>
          <div className={styles["root__link--button"]}>
            <div>
              <button
                className={
                  activeButton === 1 ? styles["root__link--active"] : ""
                }
                onClick={() => setActiveButton(1)}
              >
                What’s new
              </button>
            </div>
            <div>
              <button
                className={
                  activeButton === 2 ? styles["root__link--active"] : ""
                }
                onClick={() => setActiveButton(2)}
              >
                Tips for you
              </button>
            </div>
            <div>
              <button
                className={
                  activeButton === 3 ? styles["root__link--active"] : ""
                }
                onClick={() => setActiveButton(3)}
              >
                Share your story
              </button>
            </div>
          </div>
        </div>
        <div>
          {activeButton === 1 && <WhatNew />}
          {activeButton === 2 && <TipsForYou />}
          {activeButton === 3 && <ShareYourStory />}
        </div>
      </div>
    </>
  );
};

export default Blog;
